<template>
  <div>
    <MusicAlert v-if="musicAlertStatus" @closeAlert="musicAlertStatus = false" />
    <AlertBrandMusic v-if="isAlertBrandMusic" @closeModal="isAlertBrandMusic = false" />
    <ReleaseAlert v-if="isReleaseAlert" @closeModal="isReleaseAlert = false" />
    <SetTimeBrandSound
      v-if="isSetTime"
      @closeModal="isSetTime = false"
      @getList="getBrandMusicList()"
      :musicInfo="musicInfo"
    />
    <div class="brand_modal_box">
      <div class="brand_modal_title modal_title">
        <h1>브랜드 음원 설정</h1>
      </div>
      <div class="table_top">
        <table class="brand_table">
          <thead>
            <tr>
              <th>브랜드 음악명</th>
              <th>시간설정</th>
              <th>재생여부</th>
              <th>듣기</th>
            </tr>
          </thead>
        </table>
      </div>
      <div class="brand_scroll_body">
        <table class="brand_table">
          <tbody>
            <template v-for="(item, index) in brandMusicList">
              <tr :key="index">
                <td :class="{ common_dim: item.spaceId == null }">
                  <div :class="{ common_icon: item.spaceId == null }" @click="isAlertBrandMusic = true">
                    <div v-if="item.spaceId == null">
                      <p>공통</p>
                    </div>
                    <p class="music_title" v-html="item.brandMusicNm" />
                  </div>
                </td>
                <td class="select-time" :class="{ common_dim: item.spaceId == null }">
                  <div
                    class="select-box-area"
                    v-if="item.playType === 'R'"
                    @mouseover="showIcon($event, item)"
                    @mouseleave="hideIcon($event)"
                    @click="timeSetting(item)"
                  >
                    <span class="play_type_r">반복</span><span>{{ item.startTime }}부터</span
                    ><span>{{ item.repeatTime }}시간마다</span><img src="/media/img/icon_update.svg" alt="" />
                  </div>
                  <div
                    class="select-box-area"
                    v-else-if="item.playType === 'S'"
                    @mouseover="showIcon($event, item)"
                    @mouseleave="hideIcon($event)"
                    @click="timeSetting(item)"
                  >
                    <span class="play_type_s">지정</span><span>{{ item.selectTime }}</span
                    ><img src="/media/img/icon_update.svg" alt="" />
                  </div>
                  <div v-else><button @click="timeSetting(item)">설정하기</button></div>
                </td>
                <td :class="{ common_dim: item.spaceId == null }">
                  <div class="alarm_box">
                    <span :class="{ active1: item.playAt === 'Y' }" @click="updateBrandMusicPlayAt(item)"></span>
                    <span
                      class="move"
                      :class="{ active1_1: item.playAt === 'Y' }"
                      @click="updateBrandMusicPlayAt(item)"
                    ></span>
                  </div>
                </td>
                <td>
                  <div class="pre_play">
                    <img
                      v-bind:id="'img' + index"
                      src="/media/img/brand_Play.svg"
                      alt=""
                      @click="onPlay(item.brandMusicId, $event)"
                    />
                    <div class="sound_updown">
                      <span hidden></span>
                      <span v-bind:style="{ width: $store.state.playNav + '%' }" hidden></span>
                      <input
                        v-bind:id="'seekslider' + index"
                        type="range"
                        min="0"
                        max="100"
                        step="1"
                        value="0"
                        hidden
                      />
                      <video v-bind:id="'bVideo' + index" width="100%" height="1" hidden playsinline></video>
                    </div>
                  </div>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import MusicAlert from '@/components/modal/Space/PreferMusicAlert.vue';
import AlertBrandMusic from '@/components/modal/Player/AlertBrandMusic.vue';
import ReleaseAlert from '@/components/modal/Player/releaseAlert.vue';
import SetTimeBrandSound from '@/components/modal/buildingSpace/setTime.vue';
import { defineComponent } from '@vue/composition-api';
import axios from 'axios';
import VueCookies from 'vue-cookies';
import Hls from 'hls.js';
import $ from 'jquery';

export default defineComponent({
  data() {
    return {
      brandMusicList: [],
      musicAlertStatus: false,
      isSetTime: false,
      isAlertBrandMusic: false,
      isReleaseAlert: false,
      selectTime: 'Y',
      repeatTime: 'N',
      musicInfo: {
        brandMusicId: String,
        fileName: String,
        soundTitle: String,
        mySoundDetail: {
          brandSpaceMusicId: String,
          selectTime: '09:00',
          repeatTime: '1',
          startTime: '09:00',
          playType: String
        }
      },
      playUrl: null
    };
  },
  components: {
    MusicAlert,
    SetTimeBrandSound,
    AlertBrandMusic,
    ReleaseAlert
  },
  mounted() {
    console.log('setBrandMusic');
    this.getBrandMusicList();
    this.getMySpaceInfo();
  },
  methods: {
    showIcon(e, music) {
      if (music.spaceId != null) $(e.currentTarget).addClass('on');
    },
    hideIcon(e) {
      $(e.currentTarget).removeClass('on');
    },
    timeSetting(music) {
      // 지정/반복 시간 설정 팝업
      if (music.spaceId == null) {
        this.isAlertBrandMusic = true;
      } else {
        this.musicInfo.brandMusicId = music.brandMusicId;
        this.musicInfo.mySoundDetail.playType = music.playType;
        this.musicInfo.mySoundDetail.selectTime = music.selectTime;
        this.musicInfo.mySoundDetail.repeatTime = music.repeatTime;
        this.musicInfo.mySoundDetail.startTime = music.startTime;
        this.isSetTime = true;
      }
    },
    getMySpaceInfo() {
      var spaceId = VueCookies.get('spaceId');
      var aToken = VueCookies.get('aToken');
      var headers = { 'X-AUTH-TOKEN': aToken };

      axios
        .post('/api/profile/getMySpaceInfo', { spaceId }, { headers })
        .then(res => {
          if (res.data.resultCd === '0000') {
            this.$store.commit('setSpaceNm', res.data.result.spaceNm);
          }
        })
        .catch(e => {
          console.log(e);
        });
    },
    async getBrandMusicList() {
      const spaceId = VueCookies.get('spaceId');
      const aToken = VueCookies.get('aToken');
      const headers = { 'X-AUTH-TOKEN': aToken };

      await axios
        .post('/api/player/getBrandSpaceMusicList', { spaceId }, { headers })
        .then(res => {
          if (res.data.resultCd === '0000') {
            const result = res.data.result;
            this.brandMusicList = result;
          }
        })
        .catch(err => {
          console.log(err);
        });

      await axios
        .post('/api/player/getMySoundeMusicList', { spaceId }, { headers })
        .then(res => {
          for (const i in res.data.result) {
            res.data.result[i].listType = 'personal';
            this.brandMusicList.push(res.data.result[i]);
          }
        })
        .catch(e => {
          console.log(e);
        });
    },
    onPlay(url, e) {
      if (!this.$store.state.isPlay) {
        var videoId = $(e.currentTarget.parentElement).find('video')[0].id;
        var video = document.getElementById(videoId);
        var seek = $(e.currentTarget.parentElement).find('input')[0].id;
        var imgId = e.currentTarget.id;
        if (this.playUrl == null || this.playUrl !== url) {
          this.playerListener(url, seek, videoId, imgId);
        }
        $(e.currentTarget).attr('src', (index, attr) => {
          if (attr.match('_Play')) {
            video.play();
            return attr.replace('_Play.svg', '_Pause.svg');
          } else {
            video.pause();
            return attr.replace('_Pause.svg', '_Play.svg');
          }
        });
      } else {
        this.musicAlertStatus = true;
      }
    },
    playerListener(url, seek, videoId, imgId) {
      this.playUrl = url;
      // const _store = this.$store;
      const config = {
        // debug: true,
        // startPosition: 150,
        enableWorker: true,
        lowLatencyMode: true,
        backBufferLength: 90
      };

      let hls = null;
      const seekslider = document.getElementById(seek);
      let video = document.getElementById(videoId);

      seekslider.addEventListener('click', vidSeek, false);
      video.addEventListener('timeupdate', seektimeupdate, false);
      video.addEventListener('ended', () => {
        $('#' + imgId).attr('src', (index, attr) => {
          video.pause();
          return attr.replace('_Pause.svg', '_Play.svg');
        });
      });

      function vidSeek() {
        var seekto = video.duration * (seekslider.value / 100);
        video.currentTime = seekto;
      }

      function seektimeupdate() {
        var newtime = video.currentTime * (100 / video.duration);
        seekslider.value = newtime;
        // _store.commit("setPlayNav", newtime);
      }

      // 최초 음원 목록 재생
      playMusic(url);

      function playMusic(url) {
        const tg =
          window.location.host.indexOf('localhost') !== -1
            ? 'http://13.209.152.125:10084/stream/getMusic?' + url
            : '/stream/getMusic?' + url;
        // const tg = "http://13.209.152.125:10084" + url;
        if (Hls.isSupported()) {
          if (hls != null) hls.destroy(); // removed all listeners
          hls = new Hls(config);
          hls.loadSource(tg);
          hls.attachMedia(video);
          // addListener(); //add player listener
        } else if (video != null && video.canPlayType('application/vnd.apple.mpegurl')) {
          // iOS인 경우로 따로 대응해주어야 한다.
          let v = document.getElementById(videoId);
          const pa = video.parentElement;

          // programmatic 하게 플레이어를 생성해야 한다.
          pa.removeChild(v);
          v = document.createElement(videoId);
          v.id = 'video';
          v.playsInline = true;

          const s = document.createElement('source');
          s.type = 'application/vnd.apple.mpegurl';

          v.appendChild(s);
          pa.appendChild(v);
          video = v;
          // addListener(); //add video player listener
          v.querySelector('source').src = tg;
          v.play(); // autoplay for next song
        } else {
          // todo - temporary error message -> 나중에 에러를 위한 처리(서버 로깅 등)를 해야 한다.
          alert('Sorry! Music player has an error.');
        }
      }
    },
    updateBrandMusicPlayAt(music) {
      if (music.spaceId == null) {
        this.isAlertBrandMusic = true;
      } else {
        if (music.playAt === 'N' && music.playType === null) {
          this.isReleaseAlert = true;
        } else {
          music.playAt === 'Y' ? (music.playAt = 'N') : (music.playAt = 'Y');
          const brandMusicId = music.brandMusicId;
          const spaceId = VueCookies.get('spaceId');
          const aToken = VueCookies.get('aToken');
          const headers = { 'X-AUTH-TOKEN': aToken };

          axios.post('/api/player/updateBrandSpaceMusicPlayAt', { spaceId, brandMusicId }, { headers }).catch(err => {
            console.log(err);
          });
        }
      }
    }
  }
});
</script>

<style scoped>
.brand_modal_title h1 {
  color: #fff;
  font-size: 38px;
  font-weight: 600;
  letter-spacing: -0.5px;
  margin-bottom: 0 !important;
}
.brand_modal_title {
  margin-bottom: 42px !important;
  padding: 0 !important;
}
.brand_modal_box {
  width: 100% !important;
  height: 100% !important;
  max-width: 1200px;
  padding: 132px 0 0;
  margin: 0 auto;
}
.modal_box {
  background: rgba(21, 21, 21, 0.4);
  padding-bottom: 50px;
}
.table_top {
  padding-right: 0;
}
.brand_table tbody tr td .select-box-area.repeat-time {
  float: left;
  margin-left: 5px;
}
.brand_table tbody tr td.select-time .select-box-area {
  margin: 0 auto;
  float: none;
  width: 140px;
  height: 48px;
}
.brand_table tbody tr td.alarm .alarm_box {
  margin: 0 auto;
  float: none;
  width: 45px;
}
.brand_table tbody tr td.preview .play {
  margin: 0 auto;
  float: none;
}
/* .brand_table tbody tr td:last-child>div {
    position: relative;
    right: 13%;
  } */
.brand_scroll_body {
  height: 90% !important;
  padding: 0 0 55px 0 !important;
}
.select-box-dropDown p {
  margin: 0;
}
.modal {
  display: block;
}
.brand_modal {
  z-index: 50;
  background: rgba(21, 21, 21, 0.4);
}
.select-box::after {
  background: none;
}
.disabled {
  opacity: 0.3;
}
input[type='range']:focus {
  outline: none;
  box-shadow: none;
}
/* .brand_table tbody tr td .sound_updown input[type=range] {position:absolute; padding: 0; width:100%; -webkit-appearance: none; background-color: transparent; height: 3px; outline: none; cursor: pointer; margin-bottom: 14px;} */
input[type='range'] {
  position: absolute;
  padding: 0;
  width: 100%;
  -webkit-appearance: none;
  background-color: #ffffff;
  height: 3px;
  outline: none;
  cursor: pointer;
  margin-bottom: 14px;
}
input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  background: #ffffff;
  cursor: pointer;
  height: 14px;
  width: 14px;
  border-radius: 35px;
}

/* 3.23 공통 브랜드 음원 추가로 인한 CSS 수정 */
.brand_modal_box .brand_modal_title {
  padding-right: 0;
}
.brand_modal_box .table_top {
  padding-right: 0;
}
.brand_modal_box .table_top .brand_table thead tr {
  display: flex;
  align-content: center;
}
.brand_modal_box .table_top .brand_table thead tr th:nth-child(1) {
  width: 40%;
}
.brand_modal_box .table_top .brand_table thead tr th:nth-child(2) {
  width: 35%;
}
.brand_modal_box .table_top .brand_table thead tr th:nth-child(3) {
  width: 15%;
}
.brand_modal_box .table_top .brand_table thead tr th:nth-child(4) {
  width: 10%;
}

.brand_modal_box .brand_scroll_body {
  height: 400px;
  padding: 0;
}
.brand_modal_box .brand_scroll_body .brand_table tbody tr {
  display: flex;
  align-items: center;
  height: 60px;
  justify-content: space-between;
}
.brand_modal_box .brand_scroll_body .brand_table tbody tr td:nth-child(1) {
  width: 40%;
}
.brand_modal_box .brand_scroll_body .brand_table tbody tr td:nth-child(1) p.music_title {
  width: calc(100% - 61px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.brand_modal_box .brand_scroll_body .brand_table tbody tr td:nth-child(2) {
  display: flex;
  align-content: center;
  justify-content: space-between;
  width: 35%;
}
.brand_modal_box .brand_scroll_body .brand_table tbody tr td .select-box {
  float: none;
  display: flex;
  align-items: center;
  width: 140px;
  padding: 10px 16px;
  margin: 0;
  background: #3b3b3b;
}
.brand_modal_box .brand_scroll_body .brand_table tbody tr td:nth-child(3) {
  width: 15%;
}
.brand_modal_box .brand_scroll_body .brand_table tbody tr td:nth-child(4) {
  width: 10%;
}
/* 3.23 */
/* 3.30 브랜드 음원 설정 UI 변경*/
.brand_table tbody tr td:nth-child(1) div {
  pointer-events: none;
}
.brand_table tbody tr td:nth-child(1) div.common_icon {
  cursor: pointer;
  pointer-events: auto;
}
.brand_table tbody tr td.select-time {
  text-align: center;
}
.brand_table tbody tr td.select-time .select-box-area {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60px;
  cursor: pointer;
}
.brand_table tbody tr td.select-time div {
  margin: 0 auto;
}

.brand_table tbody tr td.select-time div.mysound {
  display: flex;
  justify-content: center;
}
.brand_table tbody tr td.select-time button {
  width: 66px;
  height: 28px;
  padding: 4px 6px;
  background: hsla(0, 0%, 100%, 0.08);
  border: 1px solid hsla(0, 0%, 100%, 0.4);
  border-radius: 4px;
  color: hsla(0, 0%, 100%, 0.87);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.5px;
}
.select-box-area .play_type_r {
  background: rgba(175, 87, 255, 0.16);
  border-radius: 100px;
  padding: 2px 4px;
  margin-right: 8px;
  color: #af57ff;
  font-weight: 500;
  font-size: 13px;
  line-height: 19px;
}
.select-box-area .play_type_s {
  background: rgba(255, 158, 45, 0.16);
  border-radius: 100px;
  padding: 2px 4px;
  margin-right: 8px;
  color: #ff9e2d;
  font-weight: 500;
  font-size: 13px;
  line-height: 19px;
}
.select-box-area span:nth-child(2),
.select-box-area span:nth-child(3) {
  color: #fff;
  padding: 2px 4px;
  margin-right: 6px;
  background: rgba(255, 255, 255, 0.08);
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}
.brand_table tbody tr td .select-box-area:hover {
  background: rgba(255, 255, 255, 0.04);
}
.brand_table tbody tr td .select-box-area img {
  position: absolute;
  left: 80%;
  bottom: 40%;
  display: none;
}
.brand_table tbody tr td .select-box-area.on {
  border-radius: 0;
}
.brand_table tbody tr td .select-box-area.on img {
  position: absolute;
  left: 90%;
  bottom: 40%;
  display: block;
}
.brand_table tbody tr td.common_dim {
  opacity: 0.4;
}
.brand_table tbody tr td.common_dim .select-box-area:hover {
  background: transparent;
}

.pre_play img {
  width: 16px;
  height: 20px;
  margin: 0 auto;
  cursor: pointer;
}
/* 3.30  */
</style>
